import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import PolicyLibraryAppNew from 'client/app/apps/policy-library/PolicyLibraryApp.new';
import PolicyLibraryAppOld from 'client/app/apps/policy-library/PolicyLibraryApp.old';
import DetailsView from 'client/app/components/Parameters/Policy/components/DetailsView';
import { DetailsViewTitle } from 'client/app/components/Parameters/Policy/components/DialogTitle';
import { LiquidPolicy } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Colors from 'common/ui/Colors';

export default function PolicyLibraryScreen() {
  const isEnabledLiquidPolicyLibrary = useFeatureToggle('LIQUID_POLICY_LIBRARY');

  const [policy, setPolicy] = useState<LiquidPolicy | undefined>();

  return isEnabledLiquidPolicyLibrary ? (
    <>
      <PolicyLibraryAppNew onViewDetails={setPolicy} />
      {policy && (
        <PolicyPreviewDialog policy={policy} onClose={() => setPolicy(undefined)}>
          <DetailsView policy={policy} />
        </PolicyPreviewDialog>
      )}
    </>
  ) : (
    <PolicyLibraryAppOld />
  );
}

function PolicyPreviewDialog({
  policy,
  onClose,
  children,
}: Pick<DialogProps, 'onClose' | 'children'> & { policy: LiquidPolicy }) {
  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <DetailsViewTitle
          name={policy.name}
          author={policy.createdBy?.displayName ?? 'Synthace'}
          createDate={policy.createdAt}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={event => {
            onClose?.(event, 'escapeKeyDown');
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DialogTitle = styled(MuiDialogTitle)({
  backgroundColor: Colors.GREY_5,
  borderBottom: `1px solid ${Colors.GREY_30}`,
});

const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  padding: 0,
  backgroundColor: Colors.GREY_5,
});

const DialogActions = styled(MuiDialogActions)({
  backgroundColor: Colors.GREY_5,
});
